/* eslint-disable react/prop-types */
// material-ui
import {
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';

// assets
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

import { EmailIcon } from 'material-icons';

import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import Twitter from '@mui/icons-material/Twitter';

import FacebookIcon from 'assets/images/footer/icon-facebook.png';
import TwitterIcon from 'assets/images/footer/icon-twitter.png';

import RichTextEditor from 'components/RichText';

import { EventDetailsProps } from '../pages/EventDetails';

import { formatEventDate, formatEventTime } from 'utils/formatDateTime';

import { REACT_APP_CLOUDFRONT_URL } from 'config/config';
import { SponsorshipIcon } from 'material-icons';
import CheckoutForm from '../modals/Checkout';
import { useEffect, useState } from 'react';

const ShareButton = ({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon: any;
  onClick: () => void;
}) => {
  return (
    <Tooltip title={title} placement="top">
      <IconButton onClick={onClick} size="small">
        <img
          src={icon}
          alt={title}
          style={{
            backgroundColor: '#673ab7',
            borderRadius: 24,
            border: '2px solid #673ab7',
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

function EventInfo({ eventDetails }: { eventDetails: EventDetailsProps }) {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [showCheckout, setShowCheckout] = useState(false);

  const imageUrl = eventDetails.imageUrl || 'EventHiDefault.jpeg';

  const endsAtFormatted = new Intl.DateTimeFormat('en-US', {
    timeZone: eventDetails.timezone,
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'shortGeneric',
  }).format(new Date(eventDetails.endsAt));

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://www.eventhi.io/events/${eventDetails.nameSlug}&amp;src=sdkpreparse`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=https://www.eventhi.io/events/${eventDetails.nameSlug}`;
  const googleMapsUrl = `https://maps.google.com/maps?q=${eventDetails.eventLocation?.streetAddress.replaceAll(
    ' ',
    '+'
  )}+${eventDetails.eventLocation?.city.replaceAll(' ', '+')}+${
    eventDetails.eventLocation?.state
  }+${eventDetails.eventLocation?.postalCode}}`;

  const handleShare = (type: string) => {
    switch (type) {
      case 'facebook':
        window.open(facebookShareUrl, '_blank');
        break;
      case 'twitter':
        window.open(twitterShareUrl, '_blank');
        break;
    }
  };

  const shareLinks = eventDetails.allowShare
    ? [
        {
          title: 'Share to Facebook',
          icon: FacebookIcon,
          onClick: () => handleShare('facebook'),
        },
        {
          title: 'Share to Twitter',
          icon: TwitterIcon,
          onClick: () => handleShare('twitter'),
        },
      ]
    : [];

  useEffect(() => {
    console.log(eventDetails);
  }, []);

  return (
    <Container sx={{ margin: 'auto' }}>
      {/* Image */}
      <Grid item xs={12}>
        <Card sx={{ m: '0 auto', border: 'none' }}>
          <CardMedia
            component="img"
            image={`${REACT_APP_CLOUDFRONT_URL}/${imageUrl}`}
            sx={{
              objectFit: 'contain',
              position: 'relative',
              top: 0,
              left: 0,
              height: 'inherit',
              width: '100%',
            }}
          />
        </Card>
      </Grid>
      <Grid container spacing={1}>
        {/* Event Title */}
        <Grid item xs={12}>
          <Stack alignItems="center" justifyContent="space-between">
            {eventDetails.status === 'Canceled' && (
              <Typography
                fontFamily="Montserrat"
                fontWeight="bold"
                variant="h1"
                sx={{ pt: 2 }}
              >
                THIS EVENT HAS BEEN CANCELED
              </Typography>
            )}
            <Typography
              fontFamily="Montserrat"
              fontWeight="bold"
              variant="h2"
              sx={{ pt: 2 }}
            >
              {eventDetails.name}
            </Typography>
            <Grid container justifyContent="flex-end">
              {shareLinks.map((link) => (
                <ShareButton key={link.title} {...link} />
              ))}
            </Grid>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              startIcon={<ConfirmationNumberOutlinedIcon />}
              onClick={() => setShowCheckout(true)}
              sx={{ mt: 2 }}
              disabled={eventDetails.status === 'Canceled'}
            >
              Get Tickets
            </Button>
            {eventDetails.sponsorships &&
              eventDetails.sponsorships.length > 0 && (
                <Button
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  startIcon={<SponsorshipIcon />}
                  onClick={() =>
                    navigate(`/events/${eventDetails.nameSlug}/sponsorship`)
                  }
                  sx={{ mt: 2 }}
                >
                  View Sponsorship Page
                </Button>
              )}
          </Stack>
        </Grid>
        {/* Description & Time/Place */}
        <Grid item xs={12}>
          <Grid container direction={matchDownMD ? 'column-reverse' : 'row'}>
            {/* Description 10 */}
            <Grid item xs={12} md={9}>
              
              {RichTextEditor && (
                <RichTextEditor
                  eventId={eventDetails.id}
                  value={eventDetails.description}
                  readOnly={true}
                />
              )}
              <Grid
                container
                justifyContent="center"
                flexDirection="column"
                sx={{ mt: 4 }}
              >
                <Typography variant="caption" textAlign="center">
                  Hosted By
                </Typography>
                <Typography variant="h4" textAlign="center" fontWeight="400">
                  {eventDetails.hostname}
                </Typography>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton
                      LinkComponent="a"
                      target="_blank"
                      href={`mailto:${eventDetails.contactEmail}`}
                      type="link"
                    >
                      <EmailIcon fontSize="medium" />
                    </IconButton>
                    {eventDetails.twitterLink && (
                      <IconButton
                        sx={{ color: 'black' }}
                        onClick={() =>
                          window.open(eventDetails.twitterLink, '_blank')
                        }
                      >
                        <Twitter />
                      </IconButton>
                    )}
                    {eventDetails.instagramLink && (
                      <IconButton
                        onClick={() =>
                          window.open(eventDetails.instagramLink, '_blank')
                        }
                      >
                        <Instagram />
                      </IconButton>
                    )}
                    {eventDetails.facebookLink && (
                      <IconButton
                        onClick={() =>
                          window.open(eventDetails.facebookLink, '_blank')
                        }
                      >
                        <Facebook />
                      </IconButton>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {/* Time/Place 2 */}
            <Grid item xs={12} md={3}>
              <Grid pb={3} pt={2}>
                <Grid container flexDirection="row">
                  <EventOutlinedIcon sx={{ fontSize: '48px' }} />
                  <Typography variant="h4" mt={3}>
                    When
                  </Typography>
                </Grid>
                <Divider />
                <Grid item>
                  <ul>
                    <li>
                      {formatEventDate(
                        eventDetails.startsAt,
                        eventDetails.timezone
                      )}
                    </li>
                    <li>
                      {formatEventTime(
                        eventDetails.startsAt,
                        eventDetails.timezone
                      )}
                      {' to '}
                      {endsAtFormatted}
                    </li>
                  </ul>
                </Grid>
              </Grid>
              <Grid pb={3}>
                <Grid container flexDirection="row">
                  <PlaceOutlinedIcon sx={{ fontSize: '48px' }} />
                  <Typography variant="h4" mt={3}>
                    Where
                  </Typography>
                </Grid>
                <Divider />
                <Grid item pl={3}>
                  {eventDetails.isVirtual && (
                    <Typography variant="h6">
                      This event is virtual. Join from anywhere!
                    </Typography>
                  )}
                  {!eventDetails.isVirtual &&
                    eventDetails.eventLocation &&
                    !eventDetails.hideLocation && (
                      <Grid
                        item
                        sx={{
                          pt: 1,
                          ':hover': { color: 'lightBlue', cursor: 'pointer' },
                        }}
                        onClick={() => window.open(googleMapsUrl, '_blank')}
                      >
                        {eventDetails.eventLocation.name} <br />
                        {eventDetails.eventLocation.formattedAddress}
                      </Grid>
                    )}
                  {!eventDetails.isVirtual &&
                    eventDetails.eventLocation &&
                    eventDetails.hideLocation && (
                      <Typography variant="h6" sx={{ pt: 1 }}>
                        {eventDetails.eventLocation.city}
                        {', '}
                        {eventDetails.eventLocation.state}
                      </Typography>
                    )}
                </Grid>
              </Grid>
              <Grid pb={3}>
                <Grid container flexDirection="row">
                  <LightbulbOutlinedIcon sx={{ fontSize: '48px' }} />
                  <Typography variant="h4" mt={3}>
                    Details
                  </Typography>
                </Grid>
                <Divider />
                <Grid item>
                  {eventDetails.userId === 39 ? (
                    <ul>
                      <li>
                        <Typography variant="h6">
                          {eventDetails.refundPolicy > 0
                            ? `Refunds accepted ${eventDetails.refundPolicy} day(s) before event`
                            : 'This event does not accept refunds'}
                        </Typography>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        <Typography
                          variant="h6"
                          color={
                            eventDetails.cannabisConsumption
                              ? 'success'
                              : 'error'
                          }
                          sx={{ pt: 1 }}
                        >
                          {eventDetails.cannabisConsumption
                            ? 'Cannabis consumption is allowed at this event'
                            : 'No cannabis is allowed at this event'}
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="h6">
                          {eventDetails.private
                            ? 'This is a private, invite-only event'
                            : 'This event is open to the public'}
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="h6">
                          {eventDetails.refundPolicy > 0
                            ? `Refunds accepted ${eventDetails.refundPolicy} day(s) before event`
                            : 'This event does not accept refunds'}
                        </Typography>
                      </li>
                    </ul>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <CheckoutForm
        isOpen={showCheckout}
        eventId={eventDetails.id}
        setIsOpen={() => setShowCheckout(!showCheckout)}
        type="ticket"
      />
    </Container>
  );
}

export default EventInfo;
