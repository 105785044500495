// Create a separate component which will load RichTextEditor only in browser
import React, { memo, useCallback } from 'react';
import { debounce } from 'lodash';

import fileManagement from 'utils/s3';
import { Box } from '@mui/material';
import { REACT_APP_AWS_BUCKET, REACT_APP_CLOUDFRONT_URL } from 'config/config';

const RichText = ({
  eventId,
  value,
  onChange,
  readOnly = true,
}: {
  eventId: number;
  value: string;
  onChange?: () => void;
  readOnly?: boolean;
}) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { RichTextEditor } = require('@mantine/rte');

    const handleChange = onChange
      ? debounce(onChange, 250)
      : console.log('Readonly');

    const uploadImage = async (file: File) => {
      const headerPath = encodeURIComponent(eventId) + '/description/';
      const fileKey = new Date().getTime();
      // const _fileTypeIndex = file.name.lastIndexOf('.');
      // const _fileType = file.name.slice(_fileTypeIndex);

      const response = await fileManagement.putItemWithPresignedUrl({
        key: headerPath + fileKey,
        body: file,
      });

      if (response) {
        return REACT_APP_CLOUDFRONT_URL + '/' + headerPath + fileKey;
      }
    };

    const handleImageUpload = useCallback((file: File): Promise<string> => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const response = await uploadImage(file);

        if (response) {
          resolve(response);
        }
      });
    }, []);

    return (
      <Box sx={{ maxWidth: '90vw' }}>
        <RichTextEditor
          readOnly={readOnly}
          controls={[
            ['bold', 'italic', 'underline', 'link', 'image', 'video'],
            ['h1', 'h2', 'h3'],
            ['unorderedList', 'orderedList'],
            ['alignLeft', 'alignCenter', 'alignRight'],
          ]}
          value={value}
          onChange={handleChange}
          onImageUpload={handleImageUpload}
          style={{
            minHeight: '500px',
            marginBottom: '20px',
            marginTop: '20px',
            border: readOnly ? 'none' : '1px solid grey',
            overflowWrap: 'break-word',
          }}
        />
      </Box>
    );
  }

  // Render anything as fallback on server, e.g. loader or html content without editor
  return null;
};

export default memo(RichText);
