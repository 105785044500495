import { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import ReactMarkdown from 'react-markdown';

import PageContainer from 'components/PageContainer';

import MerchantPolicy from 'markdown/MerchantPolicy.md';
import PrivacyPolicy from 'markdown/PrivacyPolicy.md';
import TermsOfService from 'markdown/TermsOfService.md';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Legal = () => {
  const [text, setText] = useState<string>('');
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    let _tabData = MerchantPolicy;

    switch (value) {
      case 0:
        _tabData = MerchantPolicy;
        break;
      case 1:
        _tabData = PrivacyPolicy;
        break;
      case 2:
        _tabData = TermsOfService;
        break;
      default:
        break;
    }
    fetch(_tabData)
      .then((response) => {
        return response.text();
      })
      .then((text) => setText(text));
  }, [value]);

  return (
    <PageContainer maxWidth="lg" title="Legal Policies">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="legal tabs">
          <Tab label="Merchant Policy" {...a11yProps(0)} />
          <Tab label="Privacy Policy" {...a11yProps(1)} />
          <Tab label="Terms of Service" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <ReactMarkdown>{text}</ReactMarkdown>
    </PageContainer>
  );
};

export default Legal;
