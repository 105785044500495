import { useState, useEffect, memo } from 'react';
import {
  Divider,
  FormControl,
  Grid,
  Typography,
  Select,
  TextField,
  IconButton,
  MenuItem,
  CircularProgress,
  Box,
  Avatar,
} from '@mui/material';

import { useCallService } from 'hooks';
import { DeleteIcon, TicketStubIcon } from 'material-icons';

import useCheckoutStore, { CheckoutItemState } from 'states/checkoutState';

import EventTicketTypeProps from 'types/EventTicketType';

import httpRoutes from 'utils/httpRoutes';
import { formatEventStartDate } from 'utils/formatDateTime';
import { formatCurrency } from 'utils/helpers';
import {
  generatePriceText,
  generateMinMaxLimits,
  salesEnded,
  generateFeeText,
} from 'utils/tickets';

export type TicketTypesProps = {
  eventId: number;
};

const TicketTypes = ({ eventId }: TicketTypesProps) => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState<CheckoutItemState[]>([]);

  const { state, update, setTicketList } = useCheckoutStore();

  const { callService } = useCallService();

  useEffect(() => {
    const getTicketTypes = async () => {
      const { response } = await callService({
        resource: httpRoutes.events.getAllTicketTypes(eventId),
      });

      if (response) {
        const _tickets: CheckoutItemState[] = [];

        response.forEach((ticket: EventTicketTypeProps) => {
          const _existingItemIndex = state.items.findIndex(
            (item: CheckoutItemState) => item.id === ticket.id
          );

          if (_existingItemIndex > -1) {
            _tickets.push(state.items[_existingItemIndex]);
          } else {
            _tickets.push({
              id: ticket.id,
              name: ticket.name,
              price: ticket.price,
              description: ticket.description,
              saleStartDate: ticket.saleStartDate,
              saleEndDate: ticket.saleEndDate,
              minPerOrder: ticket.minPerOrder,
              maxPerOrder: ticket.maxPerOrder,
              feesType: ticket.feesType,
              type: ticket.type,
              showQuantity: ticket.showQuantity,
              quantityRemaining: ticket.quantityRemaining,
              quantity: 0,
            });
          }
        });

        setTicketList(_tickets);
        setTickets(_tickets);
      }

      setLoading(false);
    };

    if (eventId !== 0) {
      getTicketTypes();
    }
  }, [eventId]);

  function onChangeTickets(value: number, ticketId: number): void {
    const _tickets = tickets;

    const ticketItemIndex = _tickets.findIndex((item) => item.id === ticketId);

    if (ticketItemIndex > -1) {
      _tickets[ticketItemIndex].quantity = value;

      update({ ...state, items: _tickets });
      setTickets(_tickets);
    }
  }

  const validateDiscountCode = async (
    ticketId: number,
    discountCode: string
  ) => {
    const _tickets = state.items;

    if (discountCode !== 'void') {
      const { response, error } = await callService({
        resource: httpRoutes.events.validateTicketDiscountCode(
          ticketId,
          discountCode
        ),
      });

      if (response.discountCode) {
        const _ticketIndex = _tickets.findIndex(
          (ticket) => ticket.id === response.ticketTypeId
        );

        if (_ticketIndex > -1) {
          _tickets[_ticketIndex].discountCode = response.discountCode;
          _tickets[_ticketIndex].amountToDiscount = response.discountAmount;
          _tickets[_ticketIndex].discountAmount = 0;
        }
      }

      if (error) {
        console.log('Error:', error);
      }
    } else {
      const _ticketIndex = _tickets.findIndex(
        (ticket) => ticket.id === ticketId
      );

      if (_ticketIndex > -1) {
        _tickets[_ticketIndex].discountCode = '';
        _tickets[_ticketIndex].amountToDiscount = 0;
        _tickets[_ticketIndex].discountAmount = 0;
      }
    }

    update({ ...state, items: _tickets });
    setTickets(_tickets);
  };

  useEffect(() => {
    console.log('Updating tickets');
    setTickets(state.items);
  }, [state.items]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '35rem' }}>
        <CircularProgress size="6rem" sx={{ margin: 'auto' }} />
      </Box>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" pb={3}>
          Select your tickets below!
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        {tickets.length > 0 &&
          tickets.map((ticket) => {
            const salesEndOn = formatEventStartDate(ticket.saleEndDate);
            const isSalesEnded =
              salesEnded(ticket.saleEndDate) || ticket.quantityRemaining === 0;
            const plusOrInclude =
              ticket.feesType === 'absorb' ? 'including' : '+';

            const priceText =
              ticket.type !== 'free'
                ? `${generatePriceText(
                    ticket
                  )} ${plusOrInclude} ${generateFeeText(
                    ticket,
                    state.fees
                  )} fees`
                : 'FREE';

            return (
              <Grid item key={ticket.id} xs={12} mt={2}>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Avatar aria-label={ticket.name}>
                      <TicketStubIcon />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h3" sx={{ mt: 1, ml: 1 }}>
                      {ticket.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={9} p={1}>
                    <Typography>{priceText}</Typography>
                    {isSalesEnded && (
                      <Typography variant="subtitle2">Sales Ended</Typography>
                    )}
                    {!isSalesEnded && (
                      <>
                        <Typography variant="subtitle2">Sales End</Typography>
                        <Typography variant="subtitle2">
                          {salesEndOn}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <Select
                        name="Quantity"
                        value={ticket.quantity}
                        fullWidth
                        disabled={isSalesEnded}
                        onChange={(e) => {
                          onChangeTickets(Number(e.target.value), ticket.id);
                        }}
                      >
                        {generateMinMaxLimits(ticket).map((item) => (
                          <MenuItem key={item.key} value={item.value}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Grid container spacing={2} justifyContent="flex-start">
                      <Grid item xs={4}>
                        <TextField
                          id="dicountCode"
                          label={
                            ticket.quantity === 0
                              ? 'Add tickets'
                              : 'Discount Code'
                          }
                          size="small"
                          disabled={
                            salesEnded(ticket.saleEndDate) ||
                            ticket.quantityRemaining === 0 ||
                            ticket.quantity === 0
                          }
                          onBlur={(e) => {
                            const _code = e.target.value.toString();

                            if (_code !== '') {
                              validateDiscountCode(ticket.id, e.target.value);
                            }
                          }}
                        />
                      </Grid>
                      {ticket.discountCode &&
                        ticket.discountCode !== '' &&
                        ticket.discountAmount !== undefined && (
                          <Grid item xs={5} mt={1} sx={{ color: '#56C596' }}>
                            {formatCurrency(ticket.discountAmount)} discount
                            applied!
                          </Grid>
                        )}
                      {ticket.discountCode &&
                        ticket.discountCode !== '' &&
                        ticket.discountAmount !== undefined && (
                          <Grid item xs={1}>
                            <IconButton
                              onClick={() => {
                                validateDiscountCode(ticket.id, 'void');
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                  {ticket.description && (
                    <Grid item xs={12}>
                      <Typography variant="h6" color="text.secondary" mt={1}>
                        Description
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {ticket.description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ mt: 1 }} />
              </Grid>
            );
          })}
        {tickets.length === 0 && !loading && (
          <Grid item xs={12}>
            <Typography textAlign="center">
              No tickets are currently available for sale.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TicketTypes;
